.form-control:focus,
.form-control:active,
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.nav-link {
  color: gray;
}

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}

.off,
.table>tbody>tr.off>td,
.table>thead>tr.off>th {
  color: silver;
}

.off img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* forms */
.form-group {
  width: 100%;
}

.input-group-btn .btn {
  color: silver;
}

/* modals */
.modal-header {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.modal-header h4 {
  width: 100%;
}

.modal-body {
  padding: 0px;
}

.modal-left.modal-dialog,
.modal-right.modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal-left .modal-content,
.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal-left .modal-body,
.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal-left.modal-dialog {
  left: 0;
}

.modal-right.modal-dialog {
  right: 0;
}

/* table */
.table>thead>tr>th,
.table>tbody>tr>td {
  vertical-align: middle;
  font-family: "Montserrat", sans-serif;
  color: #444;
}

.table>thead>tr>th.ord {
  cursor: pointer;
  // color: var(--cd-color-custom-tint);
}

.table>thead>tr>th.ord i {
  color: var(--cd-color-text-off);
}

.table>tbody>tr .img-col {
  width: 90px;
}

.table>tbody>tr td ion-avatar,
.table>tbody>tr td ion-thumbnail {
  --border-radius: 10px;
  margin-right: 10px;
  height: 64px;
  width: 64px;
}

.table>tbody>tr.off td ion-avatar ion-img img,
.table>tbody>tr.off td ion-thumbnail ion-img img,
.table>tbody>tr.off td ion-thumbnail ion-img,
.table>tbody>tr.off td ion-avatar ion-img {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: pointer;
}

.table>tbody>tr>td .link {
  text-decoration: underline;
  cursor: pointer;
}

/*
  // .table > tbody > tr > td > button,
  .table > tbody > tr > td > ion-button {
    --background: var(--cd-color-custom-tint);
    --background-hover: var(--cd-color-custom);
    --color: var(--cd-color-custom-contrast);
  }
  
  .table > tbody > tr > td ion-buttons ion-button {
    --background: transparent !important;
    --background-hover: var(--cd-color-custom);
    --color: var(--cd-color-custom-tint) !important;
  }
  */

// theme
/* primary */
.primary {
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary);
}

.primary.tint {
  color: var(--ion-color-primary-tint) !important;
  --color: var(--ion-color-primary-tint);
}

.primary.shade {
  color: var(--ion-color-primary-shade) !important;
  --color: var(--ion-color-primary-shade);
}

/* secondary */
.secondary {
  color: var(--ion-color-secondary) !important;
  --color: var(--ion-color-secondary);
}

.secondary.tint {
  color: var(--ion-color-secondary-tint) !important;
  --color: var(--ion-color-secondary-tint);
}

.secondary.shade {
  color: var(--ion-color-secondary-shade) !important;
  --color: var(--ion-color-secondary-shade);
}

/* tertiary
.tertiary {
  color: var(--ion-color-tertiary) !important;
  --color: var(--ion-color-tertiary);
}

.tertiary.tint {
  color: var(--ion-color-tertiary-tint) !important;
  --color: var(--ion-color-tertiary-tint);
}

.tertiary.shade {
  color: var(--ion-color-tertiary-shade) !important;
  --color: var(--ion-color-tertiary-shade);
} */

/* success */
.success {
  color: var(--ion-color-success) !important;
  --color: var(--ion-color-success);
}

.success.tint {
  color: var(--ion-color-success-tint) !important;
  --color: var(--ion-color-success-tint);
}

.success.shade {
  color: var(--ion-color-success-shade) !important;
  --color: var(--ion-color-success-shade);
}

/* warning */
.warning {
  color: var(--ion-color-warning) !important;
  --color: var(--ion-color-warning);
}

.warning.tint {
  color: var(--ion-color-warning-tint) !important;
  --color: var(--ion-color-warning-tint);
}

.warning.shade {
  color: var(--ion-color-warning-shade) !important;
  --color: var(--ion-color-warning-shade);
}

/* danger */
.danger {
  color: var(--ion-color-danger) !important;
  --color: var(--ion-color-danger);
}

.danger.tint {
  color: var(--ion-color-danger-tint) !important;
  --color: var(--ion-color-danger-tint);
}

.danger.shade {
  color: var(--ion-color-danger-shade) !important;
  --color: var(--ion-color-danger-shade);
}

/* light */
.light {
  color: var(--ion-color-light) !important;
  --color: var(--ion-color-light);
}

.light.tint {
  color: var(--ion-color-light-tint) !important;
  --color: var(--ion-color-light-tint);
}

.light.shade {
  color: var(--ion-color-light-shade) !important;
  --color: var(--ion-color-light-shade);
}

/* medium */
.medium {
  color: var(--ion-color-medium) !important;
  --color: var(--ion-color-medium);
}

.medium.tint {
  color: var(--ion-color-medium-tint) !important;
  --color: var(--ion-color-medium-tint);
}

.medium.shade {
  color: var(--ion-color-medium-shade) !important;
  --color: var(--ion-color-medium-shade);
}

/* dark */
.dark {
  color: var(--ion-color-dark) !important;
  --color: var(--ion-color-dark);
}

.dark.tint {
  color: var(--ion-color-dark-tint) !important;
  --color: var(--ion-color-dark-tint);
}

.dark.shade {
  color: var(--ion-color-dark-shade) !important;
  --color: var(--ion-color-dark-shade);
}

/* BG themes */
/* bg-primary */
.mat-button.bg-primary,
.bg-primary {
  background-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.mat-button.bg-primary.tint,
.bg-primary.tint {
  background-color: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-primary-contrast) !important;
  --background: var(--ion-color-primary-tint);
  --color: var(--ion-color-primary-contrast);
}

.mat-button.bg-primary.shade,
.bg-primary.shade {
  background-color: var(--ion-color-primary-shade) !important;
  color: var(--ion-color-primary-contrast) !important;
  --background: var(--ion-color-primary-shade);
  --color: var(--ion-color-primary-contrast);
}

.mat-button.bg-primary:hover,
.bg-primary:hover,
.mat-button.bg-primary.tint:hover,
.bg-primary.tint:hover,
.mat-button.bg-primary.shade:hover,
.bg-primary.shade:hover {
  opacity: .5;
}

/* bg-secondary */
.mat-button.bg-secondary,
.bg-secondary {
  background-color: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-contrast) !important;
  --background-color: var(--ion-color-secondary);
  --color: var(--ion-color-secondary-contrast);
}

.mat-button.bg-secondary.tint,
.bg-secondary.tint {
  background-color: var(--ion-color-secondary-tint) !important;
  color: var(--ion-color-secondary-contrast) !important;
  --background-color: var(--ion-color-secondary-tint);
  --color: var(--ion-color-secondary-contrast);
}

.mat-button.bg-secondary.shade,
.bg-secondary.shade {
  background-color: var(--ion-color-secondary-shade) !important;
  color: var(--ion-color-secondary-contrast) !important;
  --background: var(--ion-color-secondary-shade);
  --color: var(--ion-color-secondary-contrast);
}

.mat-button.bg-secondary:hover,
.bg-secondary:hover,
.mat-button.bg-secondary.tint:hover,
.bg-secondary.tint:hover,
.mat-button.bg-secondary.shade:hover,
.bg-secondary.shade:hover {
  opacity: .5;
}

/* bg-tertiary 
.mat-button.tertiary,
.tertiary {
  background-color: var(--ion-color-tertiary) !important;
  color: var(--ion-color-tertiary-contrast) !important;
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-tertiary-contrast);
}

.mat-button.tertiary.tint,
.tertiary.tint {
  background-color: var(--ion-color-tertiary-tint) !important;
  color: var(--ion-color-tertiary-contrast) !important;
  --background: var(--ion-color-tertiary-tint);
  --color: var(--ion-color-tertiary-contrast);
}

.mat-button.tertiary.shade,
.tertiary.shade {
  background-color: var(--ion-color-tertiary-shade) !important;
  color: var(--ion-color-tertiary-contrast) !important;
  --background: var(--ion-color-tertiary-shade);
  --color: var(--ion-color-tertiary-contrast);
}

.mat-button.bg-tertiary:hover,
.bg-tertiary:hover {
  background-color: var(--ion-color-tertiary-shade) !important;
  --background-color: var(--ion-color-tertiary-shade);
}

.mat-button.bg-tertiary.tint:hover,
.bg-tertiary.tint:hover {
  background-color: var(--ion-color-tertiary) !important;
  --background-color: var(--ion-color-tertiary);
}

.mat-button.bg-tertiary.shade:hover,
.bg-tertiary.shade:hover {
  background-color: var(--ion-color-tertiary-tinti) !important;
  --background: var(--ion-color-tertiary-tinti);
}
*/

/* bg-success */
.mat-button.bg-success,
.bg-success {
  background-color: var(--ion-color-success) !important;
  color: var(--ion-color-success-contrast) !important;
  --background: var(--ion-color-success);
  --color: var(--ion-color-success-contrast);
}

.mat-button.bg-success.tint,
.bg-success.tint {
  background-color: var(--ion-color-success-tint) !important;
  color: var(--ion-color-success-contrast) !important;
  --background: var(--ion-color-success-tint);
  --color: var(--ion-color-success-contrast);
}

.mat-button.bg-success.shade,
.bg-success.shade {
  background-color: var(--ion-color-success-shade) !important;
  color: var(--ion-color-success-contrast) !important;
  --background: var(--ion-color-success-shade);
  --color: var(--ion-color-success-contrast);
}

.mat-button.bg-success:hover,
.bg-success:hover,
.mat-button.bg-success.tint:hover,
.bg-success.tint:hover,
.mat-button.bg-success.shade:hover,
.bg-success.shade:hover {
  opacity: .5;
}

/* bg-warning */
.mat-button.bg-warning,
.bg-warning {
  background-color: var(--ion-color-warning) !important;
  color: var(--ion-color-warning-contrast) !important;
  --background: var(--ion-color-warning);
  --color: var(--ion-color-warning-contrast);
}

.mat-button.bg-warning.tint,
.bg-warning.tint {
  background-color: var(--ion-color-warning-tint) !important;
  color: var(--ion-color-warning-contrast) !important;
  --background: var(--ion-color-warning-tint);
  --color: var(--ion-color-warning-contrast);
}

.mat-button.bg-warning.shade,
.bg-warning.shade {
  background-color: var(--ion-color-warning-shade) !important;
  color: var(--ion-color-warning-contrast) !important;
  --background: var(--ion-color-warning-shade);
  --color: var(--ion-color-warning-contrast);
}

.mat-button.bg-warning:hover,
.bg-warning:hover,
.mat-button.bg-warning.tint:hover,
.bg-warning.tint:hover,
.mat-button.bg-warning.shade:hover,
.bg-warning.shade:hover {
  opacity: .5;
}

/* bg-danger */
.mat-button.bg-danger,
.bg-danger {
  background-color: var(--ion-color-danger) !important;
  color: var(--ion-color-danger-contrast) !important;
  --background: var(--ion-color-danger);
  --color: var(--ion-color-danger-contrast);
}

.mat-button.bg-danger.tint,
.bg-danger.tint {
  background-color: var(--ion-color-danger-tint) !important;
  color: var(--ion-color-danger-contrast) !important;
  --background: var(--ion-color-danger-tint);
  --color: var(--ion-color-danger-contrast);
}

.mat-button.bg-danger.shade,
.bg-danger.shade {
  background-color: var(--ion-color-danger-shade) !important;
  color: var(--ion-color-danger-contrast) !important;
  --background: var(--ion-color-danger-shade);
  --color: var(--ion-color-danger-contrast);
}

.mat-button.bg-danger:hover,
.bg-danger:hover,
.mat-button.bg-danger.tint:hover,
.bg-danger.tint:hover,
.mat-button.bg-danger.shade:hover,
.bg-danger.shade:hover {
  opacity: .5;
}

/* bg-light */
.mat-button.bg-light,
.bg-light {
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-light-contrast) !important;
  --background: var(--ion-color-light);
  --color: var(--ion-color-light-contrast);
}

.mat-button.bg-light.tint,
.bg-light.tint {
  background-color: var(--ion-color-light-tint) !important;
  color: var(--ion-color-light-contrast) !important;
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-light-contrast);
}

.mat-button.bg-light.shade,
.bg-light.shade {
  background-color: var(--ion-color-light-shade) !important;
  color: var(--ion-color-light-contrast) !important;
  --background: var(--ion-color-light-shade);
  --color: var(--ion-color-light-contrast);
}

.mat-button.bg-light:hover,
.bg-light:hover,
.mat-button.bg-light.tint:hover,
.bg-light.tint:hover,
.mat-button.bg-light.shade:hover,
.bg-light.shade:hover {
  opacity: .5;
}

/* bg-medium */
.mat-button.bg-medium,
.bg-medium {
  background-color: var(--ion-color-medium) !important;
  color: var(--ion-color-medium-contrast) !important;
  --background: var(--ion-color-medium);
  --color: var(--ion-color-medium-contrast);
}

.mat-button.bg-medium.tint,
.bg-medium.tint {
  background-color: var(--ion-color-medium-tint) !important;
  color: var(--ion-color-medium-contrast) !important;
  --background: var(--ion-color-medium-tint);
  --color: var(--ion-color-medium-contrast);
}

.mat-button.bg-medium.shade,
.bg-medium.shade {
  background-color: var(--ion-color-medium-shade) !important;
  color: var(--ion-color-medium-contrast) !important;
  --background: var(--ion-color-medium-shade);
  --color: var(--ion-color-medium-contrast);
}

.mat-button.bg-medium:hover,
.bg-medium:hover,
.mat-button.bg-medium.tint:hover,
.bg-medium.tint:hover,
.mat-button.bg-medium.shade:hover,
.bg-medium.shade:hover {
  opacity: .5;
}

/* bg-dark */
.mat-button.bg-dark,
.bg-dark {
  background-color: var(--ion-color-dark) !important;
  color: var(--ion-color-dark-contrast) !important;
  --background: var(--ion-color-dark);
  --color: var(--ion-color-dark-contrast);
}

.mat-button.bg-dark.tint,
.bg-dark.tint {
  background-color: var(--ion-color-dark-tint) !important;
  color: var(--ion-color-dark-contrast) !important;
  --background: var(--ion-color-dark-tint);
  --color: var(--ion-color-dark-contrast);
}

.mat-button.bg-dark.shade,
.bg-dark.shade {
  background-color: var(--ion-color-dark-shade) !important;
  color: var(--ion-color-dark-contrast) !important;
  --background: var(--ion-color-dark-shade);
  --color: var(--ion-color-dark-contrast);
}

.mat-button.bg-dark:hover,
.bg-dark:hover,
.mat-button.bg-dark.tint:hover,
.bg-dark.tint:hover,
.mat-button.bg-dark.shade:hover,
.bg-dark.shade:hover {
  opacity: .5;
}

/* breadcrumbs */
/* .breadcrumb {
  background-color: transparent; 
} */

.breadcrumb-item a {
  color: var(--ion-color-primary-tint);
}

.breadcrumb .breadcrumb-item.active a,
.breadcrumb .active {
  color: silver;
}

/* dropdown-menu
.dropdown-submenu {
  position: relative;
}

.dropdown-menu a.btn-link,
.dropdown-submenu a.btn-link {
  color: white !important;
  text-decoration: none;
}

.icon-chevron-r-thin.detail {
  color: #ccc !important;
}

.dropdown-submenu > a:after {
  // content: ">";
  // float: right;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
} */

/* .btn-primary */
.btn.btn-primary {
  background-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
  border: 1px solid var(--ion-color-primary-shade) !important;
}

.btn.btn-primary-tint {
  background-color: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-primary-contrast) !important;
  // border: 1px solid var(--ion-color-primary) !important;
}

.btn.btn-primary-shade {
  background-color: var(--ion-color-primary-shade) !important;
  color: var(--ion-color-primary-contrast) !important;
  border: 1px solid var(--ion-color-primary) !important;
}

.btn.btn-primary:hover,
.btn.btn-primary-tint:hover,
.btn.btn-primary-shade:hover {
  opacity: 0.5;
}

/* .btn-light */
.btn.btn-light {
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-light-contrast) !important;
  border: 1px solid var(--ion-color-light-shade) !important;
}

.btn.btn-light-tint {
  background-color: var(--ion-color-light-tint) !important;
  color: var(--ion-color-light-contrast) !important;
  border: 1px solid var(--ion-color-light-shade) !important;
}

.btn.btn-light-shade {
  background-color: var(--ion-color-light-shade) !important;
  color: var(--ion-color-light-contrast) !important;
  border: 1px solid var(--ion-color-light-tint) !important;
}

.btn.btn-light:hover,
.btn.btn-light-tint:hover,
.btn.btn-light-shade:hover {
  opacity: 0.5;
}

/* .btn-outline-primary */
.btn.btn-outline-primary {
  background-color: transparent !important;
  color: var(--ion-color-primary) !important;
  border: 1px solid var(--ion-color-primary) !important;
}

.btn.btn-outline-primary:hover {
  opacity: 0.5;
  // background-color: rgba(var(--ion-color-primary-rgb), .5) !important;
  // background-color: var(--ion-color-primary-tint) !important;
  // color: var(--ion-color-primary-contrast) !important;
  // border: 1px solid var(--ion-color-primary-tint) !important;
}

.btn.btn-outline-primary.active {
  background-color: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-primary-contrast) !important;
  border: none !important;
}

/* .btn-outline-light 
.btn.btn-outline-light {
  background-color: transparent !important;
  color: var(--ion-color-ligh-tint) !important;
  border: 1px solid var(--ion-color-ligh-tint) !important;
}

.btn.btn-outline-light.active {
  background-color: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-primary-contrast) !important;
  border: none !important;
}

.btn.btn-outline-light:hover {
  background-color: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-primary-contrast) !important;
  border: 1px solid var(--ion-color-primary-tint) !important;
}
*/

/* .btn-link */
.btn.btn-link {
  color: var(--ion-color-dark-tint);
  text-decoration: none;
}

.btn.btn-link-light {
  color: var(--ion-color-light);
  text-decoration: none;
}

.btn.btn-link-primary {
  color: var(--ion-color-primary);
  text-decoration: none;
}

/* .segment */
.segment .nav-pills .nav-link {
  color: var(--ion-color-primary-tint);
  border-radius: 25px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}

.segment .nav-pills .nav-link .icon {
  color: var(--ion-color-primary-tint);
  opacity: 0.5;
}

.segment .nav-pills .nav-link.active {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 25px;
}

.segment .nav-pills .nav-link.active .icon {
  color: var(--ion-color-primary-contrast);
  opacity: 1;
}

.segment .nav-pills .nav-link {
  color: var(--ion-color-primary);
}

@media (max-width: 320px) {
  .modal-left.modal-dialog,
  .modal-right.modal-dialog {
    width: 280px;
  }
}

@media (min-width: 1024px) {
  .segment .nav-pills .nav-link:hover {
    // background-color: whitesmoke;
    background-color: #eee;
    color: #666;
    // transition: resize(200%);
    // font-size: 200%;
    transition: background-color 0.25s ease 0s;
  }

  .modal-left.modal-dialog,
  .modal-right.modal-dialog {
    width: 400px;
  }
}