.w-cards {
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 4px;
  text-align: center;
  list-style-type: none;
  padding: 6px;
}

.w-card {
  background-color: white;
  // background-color: yellow;
  border: 1px solid #eee;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px;
  height: 100%;
  transition: all 0.5s;
}

.w-card--thumb {}

.w-card--content {}

.w-card--footer {}