.w-card--thumb .label-volume {
  position: absolute;
  left: -9px;
  bottom: 4px;
  background: #6f77c3;
  // background: yellow;
  box-sizing: border-box;
  color: white;
  // color: black;
  border: 1px solid rgb(93, 93, 136);
  // font-family: "Open Sans";
  font-size: 1.2rem;
  // font-weight: 600;
  // margin-top: 1rem;
  // padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  // width: 75px;
  // border-radius: 4px;
  border-radius: 0px 4px 0px 4px;
  padding: 0 8px;
  opacity: 0.8;
  cursor: default;
}

.w-card--thumb .p-tags {
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 1.0rem;
  padding: 0;
  opacity: .9;
  cursor: default;
}

.w-card--thumb .p-tags .badge {
  margin-bottom: 4px;
}

@media (min-width:  1024px) {
  .w-cards.produtos {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  } 
}
