/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@700&family=Montserrat:wght@400;700;900&display=swap');

/* Custom CSS */
@import "./assets/styles/mat.scss";
@import "./assets/styles/bs5.scss";
@import "./assets/styles/misc.scss";
@import "./assets/styles/w3.scss";
@import "./assets/styles/cards.scss";
@import "./pedidos.scss";
@import "./produtos.scss";

/* misc */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
}

body * {
  font-family: "Montserrat", sans-serif;
}

.desktop-lg {
  height: var(--app-header-desktop-l-height) !important;
}

.icon-area {
  min-width: 36px;
  // background-color: red;
}

/* .page */
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
}

.page-header {
  // height: var(--app-header-mobile-height);
  // background-color: red;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  // -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  // -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  // box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

img.logo {
  max-height: 36px;
}

.page-header mat-toolbar {
  height: var(--app-header-mobile-height);
  padding: 0px;
}

.page-footer mat-toolbar {
  height: var(--app-footer-mobile-height);
  padding: 0px;
}

.page-content {
  padding-top: var(--app-header-mobile-height) !important;
  padding-bottom: var(--app-footer-mobile-height) !important;
  flex: 1;
}

.page.no-header .page-content {
  padding-top: 0px !important;
}

.page.no-footer .page-content {
  padding-bottom: 0px !important;
}

.page-footer {
  height: var(--app-footer-mobile-height);
  // background-color: red;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
}

/* Go top button */
#goTopBtn {
  //   display: block;
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 999;
  // font-size: 1.4rem;
  border: none;
  outline: none;
  background-color: rgba(32, 32, 32, 0.8);
  // background-color: var(--ion-color-dark-tint);
  border: 3px solid white;
  // background-color: rgba(0, 0, 0, 0.7);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  opacity: 0.9;
}

/* .btn-qtde */
.btn.btn-qtde {
  background-color: var(--ion-color-primary-tint);
  color: var(--ion-color-primary-contrast);
  pointer-events: none;
  position: relative;
}

.btn-qtde .qtde-icon {
  opacity: .4;
}

.btn-qtde.light .qtde-icon {
  opacity: 1;
}

.btn-qtde .qtde-icon:hover {
  opacity: 1;
}

.btn-qtde.light {
  background-color: #eee;
  color: #aaa !important;
}

.btn-qtde.light .qtde-icon:hover {
  color: #444 !important;
}

.btn-qtde .qtde-icon {
  position: absolute;
  font-size: 1.6rem !important;
  cursor: pointer;
  pointer-events: all;
}

.btn-qtde .qtde-icon.qtde-icon-left {
  position: absolute;
  top: 0px;
  left: 2px;
}

.btn-qtde .qtde-icon.qtde-icon-right {
  position: absolute;
  top: 0px;
  right: 2px;
}

/* .owl-carousel */
.owl-carousel .owl-stage {
  display: flex !important;
}

/* .hero-banners */
.hero-banners .owl-stage-outer {
  position: relative;
}

.hero-banners .owl-prev,
.hero-banners .owl-next {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: transparent !important;
  transition: .3s all ease;
}

.hero-banners .owl-prev i,
.hero-banners .owl-next i {
  color: white;
  // text-shadow: 2px 2px #666666;
  font-size: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hero-banners .owl-prev {
  left: 50px;
}

.hero-banners .owl-next {
  right: -60px;
}

.hero-banners .owl-prev i {
  left: 25px;
}

.hero-banners .owl-next i {
  right: -15px;
}

.hero-banners .owl-prev:hover::before,
.hero-banners .owl-next:hover::before {
  background-color: #00000050;
  // background-color: var(--ion-color-primary-tint) !important;
  // opacity: .5;
}

.hero-banners .owl-prev::before,
.hero-banners .owl-next::before {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  background-color: #ffffff25;
  content: '';
  border-radius: 50%;
}

.hero-banners .owl-prev::before {
  left: 0px;
}

.hero-banners .owl-next::before {
  right: -112px;
}

/* .similares-cards */
.similares-cards .owl-stage-outer {
  position: relative;
}

.similares-cards .owl-prev,
.similares-cards .owl-next {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: transparent !important;
  transition: .3s all ease;
}

.similares-cards .owl-prev i,
.similares-cards .owl-next i {
  color: white;
  // text-shadow: 2px 2px #666666;
  font-size: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.similares-cards .owl-prev {
  left: 50px;
}

.similares-cards .owl-next {
  right: -60px;
}

.similares-cards .owl-prev i {
  left: 25px;
}

.similares-cards .owl-next i {
  right: -15px;
}

.similares-cards .owl-prev:hover::before,
.similares-cards .owl-next:hover::before {
  background-color: #00000090;
  // background-color: var(--ion-color-primary-tint) !important;
  // opacity: .5;
}

.similares-cards .owl-prev::before,
.similares-cards .owl-next::before {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  background-color: #00000070;
  content: '';
  border-radius: 50%;
}

.similares-cards .owl-prev::before {
  left: 0px;
}

.similares-cards .owl-next::before {
  right: -112px;
}

/*
.produto-imgs
.produto-imgs .owl-stage-outer {
  position: relative;
}

.produto-imgs .owl-prev,
.produto-imgs .owl-next {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: transparent !important;
  transition: .3s all ease;
}

.produto-imgs .owl-prev i,
.produto-imgs .owl-next i {
  color: white;
  // text-shadow: 2px 2px #666666;
  font-size: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.produto-imgs .owl-prev {
  left: 50px;
}

.produto-imgs .owl-next {
  right: -60px;
}

.produto-imgs .owl-prev i {
  left: 25px;
}

.produto-imgs .owl-next i {
  right: -15px;
}

.produto-imgs .owl-prev:hover::before,
.produto-imgs .owl-next:hover::before {
  background-color: #00000090;
  // background-color: var(--ion-color-primary-tint) !important;
  // opacity: .5;
}

.produto-imgs .owl-prev::before,
.produto-imgs .owl-next::before {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  background-color: #00000070;
  content: '';
  border-radius: 50%;
}

.produto-imgs .owl-prev::before {
  left: 0px;
}

.produto-imgs .owl-next::before {
  right: -112px;
}
*/

@media (min-width: 1024px) {
  .page-content {
    padding-top: var(--app-header-desktop-s-height) !important;
    padding-bottom: var(--app-header-desktop-s-height) !important ;
    flex: 1;
  }

  .page-content.lg {
    padding-top: var(--app-header-desktop-l-height) !important;
    flex: 1;
  }

  /* .btn-comprar */
  .btn-comprar:hover {
    border-color: transparent;
    background-color: var(--ion-color-primary-tint);
    color: white !important;
    opacity: 1;
  }

  /* #goTopBtn */
  #goTopBtn:hover {
    background-color: rgba(32, 32, 32, 1);
    // background-color: rgba(255, 255, 255, 0.8);
    // color: #222;
    // border: 3px solid #222;
  }
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
