/* misc */
.move-up {
  position: relative;
  top: -6px;
}

/* dialog */
.no-border-dialog-container .mat-dialog-container {
  padding: 0;
}
/* .stepper-nav */
.mat-vertical-content {
  padding: 12px 0px 0px 12px !important;
  // width: 100%;
  // background-color: yellow;
}

.stepper-nav button {
  height: 60px;
  width: 60px;
  background-color: var(--ion-color-primary-tint);
  color: var(--ion-color-primary-contrast);
  border-radius: 50%;
}

.stepper-nav button:disabled {
  opacity: 0.2;
}

.stepper-nav .mat-stepper-previous {
  // color: red;
  // visibility: hidden;
  position: absolute;
  top: 50%;
  // transform: translateY(-92px);
  left: -20px;
  z-index: 10000;
  // opacity: 0.5;
  // animation: waitAbsolute 0s 500ms forwards;
}

.stepper-nav .mat-stepper-next {
  // color: red;
  // visibility: hidden;
  position: absolute;
  // transform: translateY(-92px);
  top: 50%;
  right: -25px;
  z-index: 10000;
  // opacity: 0.5;
  // animation: waitAbsolute 0s 500ms forwards;
}

.stepper-nav .mat-stepper-previous .mat-icon {
  position: relative;
  top: 5px;
  right: 6px;
  font-size: 3rem;
}

.stepper-nav .mat-stepper-next .mat-icon {
  position: relative;
  top: 5px;
  left: -20px;
  font-size: 3rem;
}

/* Button sizes */
.mat-basic-button.lg,
.mat-raised-button.lg,
.mat-stroked-button.lg,
.mat-flat-button.lg {
  // width: 48px;
  height: 48px;
  line-height: 48px;

  .mat-icon {
    font-size: 48px;
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
}

/* primary */
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-toolbar.mat-primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.mat-flat-button.mat-primary:hover,
.mat-raised-button.mat-primary:hover,
.mat-fab.mat-primary:hover,
.mat-mini-fab.mat-primary:hover {
  background-color: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
}

/* secondary */
.mat-flat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary,
.mat-toolbar.mat-secondary {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}

.mat-tab-group.mat-secondary .mat-ink-bar,
.mat-tab-nav-bar.mat-secondary .mat-ink-bar {
  background-color: var(--ion-color-secondary-shade);
  color: var(--ion-color-secondary-contrast);
}

/* tertiary */
.mat-flat-button.mat-tertiary,
.mat-raised-button.mat-tertiary,
.mat-fab.mat-tertiary,
.mat-mini-fab.mat-tertiary,
.mat-toolbar.mat-tertiary {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}

.mat-tab-group.mat-tertiary .mat-ink-bar,
.mat-tab-nav-bar.mat-tertiary .mat-ink-bar {
  background-color: var(--ion-color-tertiary-shade);
  color: var(--ion-color-tertiary-contrast);
}

/* success */
.mat-flat-button.mat-success,
.mat-raised-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success,
.mat-toolbar.mat-success {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.mat-tab-group.mat-success .mat-ink-bar,
.mat-tab-nav-bar.mat-success .mat-ink-bar {
  background-color: var(--ion-color-success-shade);
  color: var(--ion-color-success-contrast);
}

/* warning */
.mat-flat-button.mat-warning,
.mat-raised-button.mat-warning,
.mat-fab.mat-warning,
.mat-mini-fab.mat-warning,
.mat-toolbar.mat-warning {
  background-color: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

.mat-tab-group.mat-warning .mat-ink-bar,
.mat-tab-nav-bar.mat-warning .mat-ink-bar {
  background-color: var(--ion-color-warning-shade);
  color: var(--ion-color-warning-contrast);
}

/* danger */
.mat-flat-button.mat-danger,
.mat-raised-button.mat-danger,
.mat-fab.mat-danger,
.mat-mini-fab.mat-danger,
.mat-toolbar.mat-danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.mat-tab-group.mat-danger .mat-ink-bar,
.mat-tab-nav-bar.mat-danger .mat-ink-bar {
  background-color: var(--ion-color-danger-shade);
  color: var(--ion-color-danger-contrast);
}

/* light */
.mat-flat-button.mat-light,
.mat-raised-button.mat-light,
.mat-fab.mat-light,
.mat-mini-fab.mat-light,
.mat-toolbar.mat-light {
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}

.mat-tab-group.mat-light .mat-ink-bar,
.mat-tab-nav-bar.mat-light .mat-ink-bar {
  background-color: var(--ion-color-light-shade);
  color: var(--ion-color-light-contrast);
}

/* medium */
.mat-flat-button.mat-medium,
.mat-raised-button.mat-medium,
.mat-fab.mat-medium,
.mat-mini-fab.mat-medium,
.mat-toolbar.mat-medium {
  background-color: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
}

.mat-tab-group.mat-medium .mat-ink-bar,
.mat-tab-nav-bar.mat-medium .mat-ink-bar {
  background-color: var(--ion-color-medium-shade);
  color: var(--ion-color-medium-contrast);
}

/* dark */
.mat-flat-button.mat-dark,
.mat-raised-button.mat-dark,
.mat-fab.mat-dark,
.mat-mini-fab.mat-dark,
.mat-toolbar.mat-dark {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}

.mat-tab-group.mat-dark .mat-ink-bar,
.mat-tab-nav-bar.mat-dark .mat-ink-bar {
  background-color: var(--ion-color-dark-shade);
  color: var(--ion-color-dark-contrast);
}

.mat-progress-bar-buffer {
  // background-color: var(--ion-color-primary-tint);
  background-color: whitesmoke;
}

.mat-progress-bar-fill::after {
  background-color: var(--ion-color-primary-tint);
  // background-color: white;
}

// @keyframes waitAbsolute {
//   to {
//     visibility: visible;
//   }
// }
