/*
 * Miscelaneous CSS
 */

.warn-icon {
  font-size: 2.4rem;
  color: silver;
}

.warn-caption {
  color: var(--ion-color-primary-tint);
  font-size: 1.1rem;
  font-weight: bold;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .spinner-container .spinner {
//   background-color: red;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
/* colors */
.bg-wsmoke {
  background-color: whitesmoke;
}

.bg-silver {
  background-color: silver;
}

.bg-gray {
  background-color: gray;
}

/* positioning */
.p-relative {
  position: relative;
}

.prr-1 {
  position: relative;
  right: -12px;
}

.prl-1 {
  position: relative;
  left: -12px;
}

/* rem */
.rem-5 {
  font-size: 0.5rem;
}

.rem-6 {
  font-size: 0.6rem;
}

.rem-7 {
  font-size: 0.7rem;
}

.rem-8 {
  font-size: 0.8rem;
}

.rem-9 {
  font-size: 0.9rem;
}

.rem-10 {
  font-size: 1.0rem;
}

.rem-11 {
  font-size: 1.1rem;
}

.rem-12 {
  font-size: 1.2rem;
}

.rem-13 {
  font-size: 1.3rem;
}

.rem-14 {
  font-size: 1.4rem;
}

.rem-15 {
  font-size: 1.5rem;
}

.rem-16 {
  font-size: 1.6rem;
}

.rem-17 {
  font-size: 1.7rem;
}

.rem-18 {
  font-size: 1.8rem;
}

.rem-19 {
  font-size: 1.9rem;
}

.rem-20 {
  font-size: 2rem;
}

.rem-21 {
  font-size: 2.1rem;
}

.rem-22 {
  font-size: 2.2rem;
}

.rem-23 {
  font-size: 2.3rem;
}

.rem-24 {
  font-size: 2.4rem;
}

.rem-25 {
  font-size: 2.5rem;
}

.rem-26 {
  font-size: 2.6rem;
}

.rem-27 {
  font-size: 2.7rem;
}

.rem-28 {
  font-size: 2.8rem;
}

.rem-29 {
  font-size: 2.9rem;
}

.rem-30 {
  font-size: 3rem;
}

.rem-31 {
  font-size: 3.1rem;
}

.rem-32 {
  font-size: 3.2rem;
}

.rem-33 {
  font-size: 3.3rem;
}

.rem-34 {
  font-size: 3.4rem;
}

.rem-35 {
  font-size: 3.5rem;
}

.rem-36 {
  font-size: 3.6rem;
}

.rem-37 {
  font-size: 3.7rem;
}

.rem-38 {
  font-size: 3.8rem;
}

.rem-39 {
  font-size: 3.9rem;
}

.rem-40 {
  font-size: 4rem;
}

.rem-41 {
  font-size: 4.1rem;
}

.rem-42 {
  font-size: 4.2rem;
}

.rem-43 {
  font-size: 4.3rem;
}

.rem-44 {
  font-size: 4.4rem;
}

.rem-45 {
  font-size: 4.5rem;
}

.rem-46 {
  font-size: 4.6rem;
}

.rem-47 {
  font-size: 4.7rem;
}

.rem-48 {
  font-size: 4.8rem;
}

.rem-49 {
  font-size: 4.9rem;
}

.rem-50 {
  font-size: 5rem;
}

.rem-51 {
  font-size: 5.1rem;
}

.rem-52 {
  font-size: 5.2rem;
}

.rem-53 {
  font-size: 5.3rem;
}

.rem-54 {
  font-size: 5.4rem;
}

.rem-55 {
  font-size: 5.5rem;
}

.rem-56 {
  font-size: 5.6rem;
}

.rem-57 {
  font-size: 5.7rem;
}

.rem-58 {
  font-size: 5.8rem;
}

.rem-59 {
  font-size: 5.9rem;
}

.rem-60 {
  font-size: 6rem;
}

.rem-61 {
  font-size: 6.1rem;
}

.rem-62 {
  font-size: 6.2rem;
}

.rem-63 {
  font-size: 6.3rem;
}

.rem-64 {
  font-size: 6.4rem;
}

.rem-65 {
  font-size: 6.5rem;
}

.rem-66 {
  font-size: 6.6rem;
}

.rem-67 {
  font-size: 6.7rem;
}

.rem-68 {
  font-size: 6.8rem;
}

.rem-69 {
  font-size: 6.9rem;
}

.rem-70 {
  font-size: 7rem;
}

.rem-71 {
  font-size: 7.1rem;
}

.rem-72 {
  font-size: 7.2rem;
}

.rem-73 {
  font-size: 7.3rem;
}

.rem-74 {
  font-size: 7.4rem;
}

.rem-75 {
  font-size: 7.5rem;
}

.rem-76 {
  font-size: 7.6rem;
}

.rem-77 {
  font-size: 7.7rem;
}

.rem-78 {
  font-size: 7.8rem;
}

.rem-79 {
  font-size: 7.9rem;
}

.rem-80 {
  font-size: 8rem;
}

.rem-81 {
  font-size: 8.1rem;
}

.rem-82 {
  font-size: 8.2rem;
}

.rem-83 {
  font-size: 8.3rem;
}

.rem-84 {
  font-size: 8.4rem;
}

.rem-85 {
  font-size: 8.5rem;
}

.rem-86 {
  font-size: 8.6rem;
}

.rem-87 {
  font-size: 8.7rem;
}

.rem-88 {
  font-size: 8.8rem;
}

.rem-89 {
  font-size: 8.9rem;
}

.rem-90 {
  font-size: 9rem;
}

.rem-91 {
  font-size: 9.1rem;
}

.rem-92 {
  font-size: 9.2rem;
}

.rem-93 {
  font-size: 9.3rem;
}

.rem-94 {
  font-size: 9.4rem;
}

.rem-95 {
  font-size: 9.5rem;
}

.rem-96 {
  font-size: 9.6rem;
}

.rem-97 {
  font-size: 9.7rem;
}

.rem-98 {
  font-size: 9.8rem;
}

.rem-99 {
  font-size: 9.9rem;
}

.rem-100 {
  font-size: 10rem;
}

.link:hover {
  text-decoration: underline;
}

.underline,
.under {
  text-decoration: underline;
}

.line-through,
.line-thru {
  text-decoration-line: line-through;
}

/* opacity */
.o-100 {
  opacity: 1;
}

.o-90 {
  opacity: 0.9;
}

.o-80 {
  opacity: 0.8;
}

.o-70 {
  opacity: 0.7;
}

.o-60 {
  opacity: 0.6;
}

.o-50 {
  opacity: 0.5;
}

.o-40 {
  opacity: 0.4;
}

.o-30 {
  opacity: 0.3;
}

.o-20 {
  opacity: 0.2;
}

.o-10 {
  opacity: 0.1;
}

/* w */
.w-33 {
  width: 33.33%;
}

.w-50 {
  width: 50%;
}

/* .w-100 {
  width: 100%;
} */

.italic {
  font-style: italic;
}

.round {
  border-radius: 50px !important;
}

.pointer,
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

[readonly] {
  --color: var(--cd-color-text-off);
  color: var(--cd-color-text-off);
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.strong {
  font-weight: bold;
}

/* flex */
.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

/* strike */
.strike {
  // color: gray;
  position: relative;
  z-index: 1;
}

.strike span {
  /* to hide the lines from behind the text, you have to set the background color the same as the container */
  background: white;
  padding: 0 15px;
}

.strike::before {
  border-top: 1px solid silver;
  // width: 100%;
  content: "";
  margin: 0 auto;
  /* this centers the line to the full width specified */
  position: absolute;
  /* positioning must be absolute here, and relative positioning must be applied to the parent */
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

/* forms */
small.form-error {
  text-align: left !important;
  --color: var(--ion-color-danger) !important; // #ea6153;
  color: var(--ion-color-danger) !important; // #ea6153;
  // font-size: 0.7rem;
}

small.form-success {
  text-align: left !important;
  --color: var(--ion-color-success) !important; // #ea6153;
  color: var(--ion-color-success) !important; // #ea6153;
  // font-size: 0.7rem;
}

/* r-text */
.r-text {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

/* autocomplete */

/*.autocomplete {
  width: 100%;
  ion-searchbar {
    padding: 1px !important;
  }
  ion-list {
    position: absolute;
    width: inherit;
    overflow-y: scroll;
    max-height: 375%;
    z-index: 999;
    ion-item {
      img {
        max-height: 2.5rem;
        width: auto;
        margin: auto;
        display: block;
      }
    }
    ion-item:hover {
      cursor: pointer;
      --background: #f1f1f1;
    }
  }
}*/

// pagination

/*
  .navp .ngx-pagination {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .navp ul.ngx-pagination li {
    border: 1px solid whitesmoke;
    border-radius: 5px;
  }
  */

// .navp ul.ngx-pagination li {
//   // position: relative;
//   display: block-inline;
//   padding: 0.5rem 0.75rem;
//   margin-left: -3px;
//   line-height: 1.25;
//   color: #0a0a0a; // #007bff;
//   background-color: #fff;
//   border: 1px solid #dee2e6;
// }

// .navp ul.ngx-pagination .current {
//   background-color: whitesmoke;
//   font-weight: bold;
//   // font-size: 140%;
// }

// .navp ul.ngx-pagination li:hover,
// .navp ul.ngx-pagination li a:hover {
//   background-color: whitesmoke; // var(--cd-color-custom-tint); // #007bff;
//   // color: var(--cd-color-custom-inverse) !important;
// }

// .navp ul.ngx-pagination li a:hover {
//   text-decoration: none;
// }

// .navp ul.ngx-pagination:last-child li {
//   border-top-right-radius: 0.25rem;
//   border-bottom-right-radius: 0.25rem;
// }

/* Animated buttons */
// .button {
//   background-color: white;
//   color: black;
//   border: 2px solid #555555;
//   padding: 16px 32px;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 16px;
//   margin: 4px 2px;
//   -webkit-transition-duration: 0.4s; /* Safari */
//   transition-duration: 0.4s;
//   cursor: pointer;
//   border-radius: 20%;
// }

// .button:hover {
//   background-color: #555555;
//   color: white;
// }
// .button:hover {
//   background-color: #555555;
//   color: white;
// }

@keyframes floating {
	0% { transform: translate(0, 0px); }
	50% { transform: translate(0, 15px); }
	100% { transform: translate(0, -0px); }
}

.floating {
	animation-name: floating;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	margin-left: 30px;
	margin-top: 5px;
}

/*Bounce*/
@keyframes kreep {
  0% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  50% {
    -webkit-transform: scale(0.9, 1.1) translateY(-0.5rem);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.kreep {
  display: inline-block;
  width: 100%;
  /*name-duration-function(ease,eas-in,linear)-delay-count-direction */
  animation: kreep 0.7s ease 0s infinite alternate;
}

.kreep:hover {
  animation: none;
}

/*END BOUNCER*/

/*SHAKER*/
@keyframes wiggle {
  2% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  4% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  6% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  8% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  10% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  12% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  14% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  16% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  18% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }

  20% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}

.wiggle {
  display: inline-block;
  animation: wiggle 2.7s infinite;
}

.wiggle:hover {
  animation: none;
}

/*END SHAKER*/

/* .panel */

// ion-toolbar.panel {
//   --background: var(--cd-color-panel-bg);
//   // --color: var(--cd-color-panel-text);
//   // color: var(--cd-color-panel-text);
//   border-radius: 4px;
//   --padding-start: 20px;
//   --padding-end: 20px;
//   --border-color: var(cd-color-panel-border);
//   --border-style: solid;
//   --border-width: 1px;
//   margin-top: 5px;
//   margin-bottom: 0px;
// }

// ion-toolbar.panel ion-text span,
// ion-toolbar.panel ion-button span {
//   --color: var(--cd-color-panel-icons);
//   color: var(--cd-color-panel-icons);
// }

// ion-toolbar.panel ion-label h2 {
//   --color: var(--cd-color-panel-text);
//   color: var(--cd-color-panel-text);
// }

// /* expanded */

// ion-toolbar.panel.expanded,
// ion-toolbar.panel.expanded ion-label h2 {
//   --background: var(--cd-color-panel-bg-active);
//   // --color: var(--cd-color-panel-text-active);
//   // color: var(--cd-color-panel-text-active);
// }

// // ion-toolbar.panel.expanded ion-text span,
// ion-toolbar.panel.expanded ion-buttons ion-button span {
//   --color: var(--cd-color-panel-icons-active);
//   color: var(--cd-color-panel-icons-active);
// }

// ion-toolbar.panel.expanded ion-label h2 {
//   --color: var(--cd-color-panel-text-active);
//   color: var(--cd-color-panel-text-active);
// }

/* ion-slides */

// ion-slides ion-slide {
//   cursor: pointer;
//   color: silver; // var(--cd-color-text);
//   // font-weight: normal;
//   border-bottom: none;
// }

// ion-slides ion-slide.active {
//   // background-color: red;
//   color: var(--cd-color-custom-tint);
//   // font-weight: bold;
//   border-bottom: 2px solid var(--cd-color-custom-tint);
// }

// .alert-tappable.alert-radio {
//   height: auto;
//   contain: content;
// }

// .alert-radio-label.sc-ion-alert-md,
// .alert-radio-label.sc-ion-alert-ios {
//   white-space: normal;
// }
/* 
/* .dashboard 
.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // justify-items: center;
  // justify-items: stretch;
  grid-gap: 4px;
  text-align: center;
  list-style-type: none;
  padding: 6px;
  // background-color: blue;
}

.dashboard .cell {
  // text-align: center;
  // min-height: 100px;
  background-color: whitesmoke;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  pointer-events: all;
}

.dashboard .cell .icon {
  color: silver; // var(--ion-color-primary-tint);
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 50%;
}

.dashboard .cell h6 {
  color: #999;
  font-size: 0.8;
}

.dashboard .cell h1 {
  font-size: 3rem;
  color: #666;
}

.dashboard .cell.wiggle {
  border: 1px solid rgb(252, 147, 128);
} */

/* z-indexes*/
.menu-page-header mat-toolbar,
.menu-page-footer mat-toolbar {
  z-index: 1500 !important;
}

.swal2-container {
  z-index: 2000 !important;
}

.spinner-container,
.loader-container {
  z-index: 10000 !important;
}

.cdk-overlay-container,
.md-datepicker-calendar-pane,
.mat-datepicker-content .mat-calendar {
  z-index: 1200 !important;
}

/* .modal.fade {
  z-index: 50 !important;
} */

/* .cdk-overlay-container,
.cdk-global-overlay-wrapper {
  z-index: 30 !important;
} */

@media (min-width: 1024px) {
  .r-text {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}