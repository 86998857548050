.pedidos,
.pedidos-list {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

/* .pedido {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  // max-height: 300px;
  height: 100%;
}

.pedido p.nome {
  line-height: 1rem;
} */
