@font-face {
  font-family: 'conecdata';
  src: url('../font/conecdata.eot?36273699');
  src: url('../font/conecdata.eot?36273699#iefix') format('embedded-opentype'),
       url('../font/conecdata.woff2?36273699') format('woff2'),
       url('../font/conecdata.woff?36273699') format('woff'),
       url('../font/conecdata.ttf?36273699') format('truetype'),
       url('../font/conecdata.svg?36273699#conecdata') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'conecdata';
    src: url('../font/conecdata.svg?36273699#conecdata') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "conecdata";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-b-alpha-sort-asc:before { content: '\e800'; } /* '' */
.icon-b-alpha-sort-desc:before { content: '\e801'; } /* '' */
.icon-b-arrow-d:before { content: '\e802'; } /* '' */
.icon-b-arrow-l:before { content: '\e803'; } /* '' */
.icon-b-arrow-r:before { content: '\e804'; } /* '' */
.icon-b-arrow-u:before { content: '\e805'; } /* '' */
.icon-b-asterisk:before { content: '\e806'; } /* '' */
.icon-b-at:before { content: '\e807'; } /* '' */
.icon-b-attach:before { content: '\e808'; } /* '' */
.icon-b-bang:before { content: '\e809'; } /* '' */
.icon-b-bang-bang:before { content: '\e80a'; } /* '' */
.icon-b-barcode:before { content: '\e80b'; } /* '' */
.icon-b-barcode-2:before { content: '\e80c'; } /* '' */
.icon-b-barcode-scan:before { content: '\e80d'; } /* '' */
.icon-b-cancel:before { content: '\e80e'; } /* '' */
.icon-b-check:before { content: '\e80f'; } /* '' */
.icon-b-checkbox-checked:before { content: '\e810'; } /* '' */
.icon-b-checkbox-checked-2:before { content: '\e811'; } /* '' */
.icon-b-chevron-d:before { content: '\e812'; } /* '' */
.icon-b-chevron-l:before { content: '\e813'; } /* '' */
.icon-b-chevron-r:before { content: '\e814'; } /* '' */
.icon-b-chevron-u:before { content: '\e815'; } /* '' */
.icon-b-code:before { content: '\e816'; } /* '' */
.icon-b-cut:before { content: '\e817'; } /* '' */
.icon-b-cut-2:before { content: '\e818'; } /* '' */
.icon-b-dolar:before { content: '\e819'; } /* '' */
.icon-b-dolar-2:before { content: '\e81a'; } /* '' */
.icon-b-double-arrow-h:before { content: '\e81b'; } /* '' */
.icon-b-double-arrow-v:before { content: '\e81c'; } /* '' */
.icon-b-double-checked:before { content: '\e81d'; } /* '' */
.icon-b-download:before { content: '\e81e'; } /* '' */
.icon-b-edit:before { content: '\e81f'; } /* '' */
.icon-b-export:before { content: '\e820'; } /* '' */
.icon-b-first:before { content: '\e821'; } /* '' */
.icon-b-google:before { content: '\e822'; } /* '' */
.icon-b-hashtag:before { content: '\e823'; } /* '' */
.icon-b-home:before { content: '\e824'; } /* '' */
.icon-b-img:before { content: '\e825'; } /* '' */
.icon-b-import:before { content: '\e826'; } /* '' */
.icon-b-key:before { content: '\e827'; } /* '' */
.icon-b-last:before { content: '\e828'; } /* '' */
.icon-b-lines-h:before { content: '\e829'; } /* '' */
.icon-b-lines-v:before { content: '\e82a'; } /* '' */
.icon-b-link:before { content: '\e82b'; } /* '' */
.icon-b-minus:before { content: '\e82c'; } /* '' */
.icon-b-num-sort-asc:before { content: '\e82d'; } /* '' */
.icon-b-num-sort-desc:before { content: '\e82e'; } /* '' */
.icon-b-plus:before { content: '\e82f'; } /* '' */
.icon-b-power-off:before { content: '\e830'; } /* '' */
.icon-b-qrcode-2:before { content: '\e831'; } /* '' */
.icon-b-question:before { content: '\e832'; } /* '' */
.icon-b-redo:before { content: '\e833'; } /* '' */
.icon-b-refresh:before { content: '\e834'; } /* '' */
.icon-b-script:before { content: '\e835'; } /* '' */
.icon-b-search:before { content: '\e836'; } /* '' */
.icon-b-sequence:before { content: '\e837'; } /* '' */
.icon-b-shopping-cart:before { content: '\e838'; } /* '' */
.icon-b-shopping-cart-2:before { content: '\e839'; } /* '' */
.icon-b-shopping-cart-cancel:before { content: '\e83a'; } /* '' */
.icon-b-shopping-cart-cancel-2:before { content: '\e83b'; } /* '' */
.icon-b-shopping-cart-checked:before { content: '\e83c'; } /* '' */
.icon-b-shopping-cart-checked-2:before { content: '\e83d'; } /* '' */
.icon-b-sort-asc:before { content: '\e83e'; } /* '' */
.icon-b-sort-desc:before { content: '\e83f'; } /* '' */
.icon-b-store:before { content: '\e840'; } /* '' */
.icon-b-terminal:before { content: '\e841'; } /* '' */
.icon-b-thunder:before { content: '\e842'; } /* '' */
.icon-b-trolley:before { content: '\e843'; } /* '' */
.icon-b-undo:before { content: '\e844'; } /* '' */
.icon-b-upload:before { content: '\e845'; } /* '' */
.icon-b-wifi-off:before { content: '\e846'; } /* '' */
.icon-b-wifi-on:before { content: '\e847'; } /* '' */
.icon-ob-bang:before { content: '\e848'; } /* '' */
.icon-ob-bang-bang:before { content: '\e849'; } /* '' */
.icon-ob-bang-coin:before { content: '\e84a'; } /* '' */
.icon-ob-camera:before { content: '\e84b'; } /* '' */
.icon-ob-edit:before { content: '\e84c'; } /* '' */
.icon-ob-edit-2:before { content: '\e84d'; } /* '' */
.icon-ob-folder:before { content: '\e84e'; } /* '' */
.icon-ob-folder-open:before { content: '\e84f'; } /* '' */
.icon-ob-grid-big:before { content: '\e850'; } /* '' */
.icon-ob-instagram:before { content: '\e851'; } /* '' */
.icon-o-alarm:before { content: '\e852'; } /* '' */
.icon-o-arrow-coin-d:before { content: '\e853'; } /* '' */
.icon-o-arrow-coin-l:before { content: '\e854'; } /* '' */
.icon-o-arrow-coin-r:before { content: '\e855'; } /* '' */
.icon-o-arrow-coin-u:before { content: '\e856'; } /* '' */
.icon-o-arrow-d:before { content: '\e857'; } /* '' */
.icon-o-arrow-l:before { content: '\e858'; } /* '' */
.icon-o-arrow-r:before { content: '\e859'; } /* '' */
.icon-o-arrow-u:before { content: '\e85a'; } /* '' */
.icon-o-asterisk:before { content: '\e85b'; } /* '' */
.icon-o-asterisk-coin:before { content: '\e85c'; } /* '' */
.icon-o-bang:before { content: '\e85d'; } /* '' */
.icon-o-bang-bang:before { content: '\e85e'; } /* '' */
.icon-o-bang-coin:before { content: '\e85f'; } /* '' */
.icon-o-bank:before { content: '\e860'; } /* '' */
.icon-o-barcode:before { content: '\e861'; } /* '' */
.icon-o-bell:before { content: '\e862'; } /* '' */
.icon-o-bell-off:before { content: '\e863'; } /* '' */
.icon-o-bell-on:before { content: '\e864'; } /* '' */
.icon-ob-key:before { content: '\e865'; } /* '' */
.icon-ob-key-2:before { content: '\e866'; } /* '' */
.icon-o-blocked:before { content: '\e867'; } /* '' */
.icon-ob-locked:before { content: '\e868'; } /* '' */
.icon-ob-mail:before { content: '\e869'; } /* '' */
.icon-ob-pin:before { content: '\e86a'; } /* '' */
.icon-ob-transfer:before { content: '\e86b'; } /* '' */
.icon-o-bug:before { content: '\e86c'; } /* '' */
.icon-o-bulb:before { content: '\e86d'; } /* '' */
.icon-o-calc:before { content: '\e86e'; } /* '' */
.icon-o-calendar:before { content: '\e86f'; } /* '' */
.icon-o-calendar-day:before { content: '\e870'; } /* '' */
.icon-o-calendar-day-2:before { content: '\e871'; } /* '' */
.icon-o-calendar-day-3:before { content: '\e872'; } /* '' */
.icon-o-camera:before { content: '\e873'; } /* '' */
.icon-o-cancel:before { content: '\e874'; } /* '' */
.icon-o-cancel-coin:before { content: '\e875'; } /* '' */
.icon-o-car:before { content: '\e876'; } /* '' */
.icon-o-cards:before { content: '\e877'; } /* '' */
.icon-o-chat-asterisk:before { content: '\e878'; } /* '' */
.icon-o-chat-bang:before { content: '\e879'; } /* '' */
.icon-o-chat-cancel:before { content: '\e87a'; } /* '' */
.icon-o-chat-check:before { content: '\e87b'; } /* '' */
.icon-o-chat-empty:before { content: '\e87c'; } /* '' */
.icon-o-chat-full:before { content: '\e87d'; } /* '' */
.icon-o-chat-info:before { content: '\e87e'; } /* '' */
.icon-o-check:before { content: '\e87f'; } /* '' */
.icon-o-checkbox-unchecked:before { content: '\e880'; } /* '' */
.icon-o-checkbox-unchecked-2:before { content: '\e881'; } /* '' */
.icon-o-check-coin:before { content: '\e882'; } /* '' */
.icon-o-chevron-d:before { content: '\e883'; } /* '' */
.icon-o-chevron-l:before { content: '\e884'; } /* '' */
.icon-o-chevron-r:before { content: '\e885'; } /* '' */
.icon-o-chevron-u:before { content: '\e886'; } /* '' */
.icon-o-clock:before { content: '\e887'; } /* '' */
.icon-o-clock-2:before { content: '\e888'; } /* '' */
.icon-o-coffee:before { content: '\e889'; } /* '' */
.icon-o-cog:before { content: '\e88a'; } /* '' */
.icon-o-copy:before { content: '\e88b'; } /* '' */
.icon-o-copy-2:before { content: '\e88c'; } /* '' */
.icon-o-copyright:before { content: '\e88d'; } /* '' */
.icon-o-credit-card:before { content: '\e88e'; } /* '' */
.icon-o-credit-card-2:before { content: '\e88f'; } /* '' */
.icon-o-doc:before { content: '\e890'; } /* '' */
.icon-o-doc-2:before { content: '\e891'; } /* '' */
.icon-o-docs:before { content: '\e892'; } /* '' */
.icon-o-edit:before { content: '\e893'; } /* '' */
.icon-o-envelope:before { content: '\e894'; } /* '' */
.icon-o-eye:before { content: '\e895'; } /* '' */
.icon-o-eye-blocked:before { content: '\e896'; } /* '' */
.icon-o-facebook:before { content: '\e897'; } /* '' */
.icon-o-ff:before { content: '\e898'; } /* '' */
.icon-o-filter:before { content: '\e899'; } /* '' */
.icon-o-filter-2:before { content: '\e89a'; } /* '' */
.icon-o-first:before { content: '\e89b'; } /* '' */
.icon-o-folder:before { content: '\e89c'; } /* '' */
.icon-o-folder-open:before { content: '\e89d'; } /* '' */
.icon-o-graph-cols:before { content: '\e89e'; } /* '' */
.icon-o-graph-pie:before { content: '\e89f'; } /* '' */
.icon-o-graph-rows:before { content: '\e8a0'; } /* '' */
.icon-o-grid-big:before { content: '\e8a1'; } /* '' */
.icon-o-grid-small:before { content: '\e8a2'; } /* '' */
.icon-o-grid-small-2:before { content: '\e8a3'; } /* '' */
.icon-o-hashtag:before { content: '\e8a4'; } /* '' */
.icon-o-hashtag-square:before { content: '\e8a5'; } /* '' */
.icon-o-heart:before { content: '\e8a6'; } /* '' */
.icon-o-heart-2:before { content: '\e8a7'; } /* '' */
.icon-o-home:before { content: '\e8a8'; } /* '' */
.icon-o-hourglass:before { content: '\e8a9'; } /* '' */
.icon-o-hourglass-2:before { content: '\e8aa'; } /* '' */
.icon-o-hourglass-3:before { content: '\e8ab'; } /* '' */
.icon-o-instagram:before { content: '\e8ac'; } /* '' */
.icon-o-key:before { content: '\e8ad'; } /* '' */
.icon-o-last:before { content: '\e8ae'; } /* '' */
.icon-o-lines-h:before { content: '\e8af'; } /* '' */
.icon-o-lines-v:before { content: '\e8b0'; } /* '' */
.icon-o-link:before { content: '\e8b1'; } /* '' */
.icon-o-linkedin:before { content: '\e8b2'; } /* '' */
.icon-o-list:before { content: '\e8b3'; } /* '' */
.icon-o-mail:before { content: '\e8b4'; } /* '' */
.icon-o-minus:before { content: '\e8b5'; } /* '' */
.icon-o-minus-coin:before { content: '\e8b6'; } /* '' */
.icon-o-moneybag:before { content: '\e8b7'; } /* '' */
.icon-o-money-bill:before { content: '\e8b8'; } /* '' */
.icon-o-monitor:before { content: '\e8b9'; } /* '' */
.icon-o-more-coin-h:before { content: '\e8ba'; } /* '' */
.icon-o-more-coin-v:before { content: '\e8bb'; } /* '' */
.icon-o-more-h:before { content: '\e8bc'; } /* '' */
.icon-o-more-v:before { content: '\e8bd'; } /* '' */
.icon-o-new:before { content: '\e8be'; } /* '' */
.icon-o-paste:before { content: '\e8bf'; } /* '' */
.icon-o-person-coin:before { content: '\e8c0'; } /* '' */
.icon-o-phone:before { content: '\e8c1'; } /* '' */
.icon-o-pin:before { content: '\e8c2'; } /* '' */
.icon-o-play:before { content: '\e8c3'; } /* '' */
.icon-o-play-coin:before { content: '\e8c4'; } /* '' */
.icon-o-plus:before { content: '\e8c5'; } /* '' */
.icon-o-plus-coin:before { content: '\e8c6'; } /* '' */
.icon-o-pricetag:before { content: '\e8c7'; } /* '' */
.icon-o-qrcode:before { content: '\e8c8'; } /* '' */
.icon-o-question-coin:before { content: '\e8c9'; } /* '' */
.icon-o-rewind:before { content: '\e8ca'; } /* '' */
.icon-o-robot:before { content: '\e8cb'; } /* '' */
.icon-o-safe:before { content: '\e8cc'; } /* '' */
.icon-o-save:before { content: '\e8cd'; } /* '' */
.icon-o-script:before { content: '\e8ce'; } /* '' */
.icon-o-search:before { content: '\e8cf'; } /* '' */
.icon-o-share:before { content: '\e8d0'; } /* '' */
.icon-o-shopping-basket:before { content: '\e8d1'; } /* '' */
.icon-o-sidebar-off:before { content: '\e8d2'; } /* '' */
.icon-o-sidebar-on:before { content: '\e8d3'; } /* '' */
.icon-o-smartphone:before { content: '\e8d4'; } /* '' */
.icon-o-smartphone-comm:before { content: '\e8d5'; } /* '' */
.icon-o-sms:before { content: '\e8d6'; } /* '' */
.icon-o-sound-loud:before { content: '\e8d7'; } /* '' */
.icon-o-sound-mute:before { content: '\e8d8'; } /* '' */
.icon-o-sound-off:before { content: '\e8d9'; } /* '' */
.icon-o-sound-quiet:before { content: '\e8da'; } /* '' */
.icon-o-star-empty:before { content: '\e8db'; } /* '' */
.icon-o-stop:before { content: '\e8dc'; } /* '' */
.icon-o-stop-coin:before { content: '\e8dd'; } /* '' */
.icon-o-stop-hand:before { content: '\e8de'; } /* '' */
.icon-o-store:before { content: '\e8df'; } /* '' */
.icon-o-store-2:before { content: '\e8e0'; } /* '' */
.icon-o-store-3:before { content: '\e8e1'; } /* '' */
.icon-o-tag:before { content: '\e8e2'; } /* '' */
.icon-o-tags:before { content: '\e8e3'; } /* '' */
.icon-o-telegram:before { content: '\e8e4'; } /* '' */
.icon-o-thumbs-down:before { content: '\e8e5'; } /* '' */
.icon-o-thumbs-up:before { content: '\e8e6'; } /* '' */
.icon-o-transfer:before { content: '\e8e7'; } /* '' */
.icon-o-trashbin:before { content: '\e8e8'; } /* '' */
.icon-o-trashbin-2:before { content: '\e8e9'; } /* '' */
.icon-o-truck:before { content: '\e8ea'; } /* '' */
.icon-o-twitter:before { content: '\e8eb'; } /* '' */
.icon-o-unlocked:before { content: '\e8ec'; } /* '' */
.icon-o-user:before { content: '\e8ed'; } /* '' */
.icon-o-user-id:before { content: '\e8ee'; } /* '' */
.icon-o-user-pin:before { content: '\e8ef'; } /* '' */
.icon-o-wallet:before { content: '\e8f0'; } /* '' */
.icon-o-whatsapp:before { content: '\e8f1'; } /* '' */
.icon-o-window:before { content: '\e8f2'; } /* '' */
.icon-o-wrench:before { content: '\e8f3'; } /* '' */
.icon-o-www:before { content: '\e8f4'; } /* '' */
.icon-o-youtube:before { content: '\e8f5'; } /* '' */
.icon-os-checks:before { content: '\e8f6'; } /* '' */
.icon-os-ascending:before { content: '\e8f7'; } /* '' */
.icon-os-descending:before { content: '\e8f8'; } /* '' */
.icon-os-globe:before { content: '\e8f9'; } /* '' */
.icon-os-hourglass-half:before { content: '\e8fa'; } /* '' */
.icon-os-paste:before { content: '\e8fb'; } /* '' */
.icon-os-radios:before { content: '\e8fc'; } /* '' */
.icon-os-star-half:before { content: '\e8fd'; } /* '' */
.icon-r-angle-brackets:before { content: '\e8fe'; } /* '' */
.icon-r-asterisk:before { content: '\e8ff'; } /* '' */
.icon-r-at:before { content: '\e900'; } /* '' */
.icon-r-barcode:before { content: '\e901'; } /* '' */
.icon-r-barcode-scan:before { content: '\e902'; } /* '' */
.icon-r-brackets:before { content: '\e903'; } /* '' */
.icon-r-cancel:before { content: '\e904'; } /* '' */
.icon-r-check:before { content: '\e905'; } /* '' */
.icon-r-chevron-d:before { content: '\e906'; } /* '' */
.icon-r-chevron-l:before { content: '\e907'; } /* '' */
.icon-r-chevron-r:before { content: '\e908'; } /* '' */
.icon-r-chevron-u:before { content: '\e909'; } /* '' */
.icon-r-code:before { content: '\e90a'; } /* '' */
.icon-r-dolar:before { content: '\e90b'; } /* '' */
.icon-r-download:before { content: '\e90c'; } /* '' */
.icon-r-export:before { content: '\e90d'; } /* '' */
.icon-r-eye:before { content: '\e90e'; } /* '' */
.icon-r-eye-blocked:before { content: '\e90f'; } /* '' */
.icon-r-fingerprint:before { content: '\e910'; } /* '' */
.icon-r-first:before { content: '\e911'; } /* '' */
.icon-r-google:before { content: '\e912'; } /* '' */
.icon-r-hashtag:before { content: '\e913'; } /* '' */
.icon-r-home:before { content: '\e914'; } /* '' */
.icon-r-import:before { content: '\e915'; } /* '' */
.icon-r-last:before { content: '\e916'; } /* '' */
.icon-r-lines-h:before { content: '\e917'; } /* '' */
.icon-r-lines-v:before { content: '\e918'; } /* '' */
.icon-r-link:before { content: '\e919'; } /* '' */
.icon-r-list-2:before { content: '\e91a'; } /* '' */
.icon-r-math:before { content: '\e91b'; } /* '' */
.icon-r-minus:before { content: '\e91c'; } /* '' */
.icon-r-more-h:before { content: '\e91d'; } /* '' */
.icon-r-more-v:before { content: '\e91e'; } /* '' */
.icon-r-order:before { content: '\e91f'; } /* '' */
.icon-r-people:before { content: '\e920'; } /* '' */
.icon-r-percent:before { content: '\e921'; } /* '' */
.icon-r-person:before { content: '\e922'; } /* '' */
.icon-r-plus:before { content: '\e923'; } /* '' */
.icon-r-power-off:before { content: '\e924'; } /* '' */
.icon-r-question:before { content: '\e925'; } /* '' */
.icon-r-redo:before { content: '\e926'; } /* '' */
.icon-r-refresh:before { content: '\e927'; } /* '' */
.icon-r-round-brackets:before { content: '\e928'; } /* '' */
.icon-r-search:before { content: '\e929'; } /* '' */
.icon-r-sort-asc:before { content: '\e92a'; } /* '' */
.icon-r-sort-desc:before { content: '\e92b'; } /* '' */
.icon-r-square-brackets:before { content: '\e92c'; } /* '' */
.icon-r-transfer:before { content: '\e92d'; } /* '' */
.icon-r-transfer-2:before { content: '\e92e'; } /* '' */
.icon-r-undo:before { content: '\e92f'; } /* '' */
.icon-r-up-down:before { content: '\e930'; } /* '' */
.icon-r-upload:before { content: '\e931'; } /* '' */
.icon-r-user-id-v:before { content: '\e932'; } /* '' */
.icon-s-alarm:before { content: '\e933'; } /* '' */
.icon-s-arrow-coin-d:before { content: '\e934'; } /* '' */
.icon-s-arrow-coin-l:before { content: '\e935'; } /* '' */
.icon-s-arrow-coin-r:before { content: '\e936'; } /* '' */
.icon-s-arrow-coin-u:before { content: '\e937'; } /* '' */
.icon-s-asterisk-coin:before { content: '\e938'; } /* '' */
.icon-s-bang-coin:before { content: '\e939'; } /* '' */
.icon-s-bank:before { content: '\e93a'; } /* '' */
.icon-s-bell-off:before { content: '\e93b'; } /* '' */
.icon-s-bell-on:before { content: '\e93c'; } /* '' */
.icon-s-bus:before { content: '\e93d'; } /* '' */
.icon-s-calc:before { content: '\e93e'; } /* '' */
.icon-s-calendar:before { content: '\e93f'; } /* '' */
.icon-s-calendar-day:before { content: '\e940'; } /* '' */
.icon-s-camera:before { content: '\e941'; } /* '' */
.icon-s-cancel-coin:before { content: '\e942'; } /* '' */
.icon-s-check-coin:before { content: '\e943'; } /* '' */
.icon-s-clock:before { content: '\e944'; } /* '' */
.icon-s-clock-2:before { content: '\e945'; } /* '' */
.icon-s-clock-3:before { content: '\e946'; } /* '' */
.icon-s-coffee:before { content: '\e947'; } /* '' */
.icon-s-cog:before { content: '\e948'; } /* '' */
.icon-s-copy:before { content: '\e949'; } /* '' */
.icon-s-credit-card:before { content: '\e94a'; } /* '' */
.icon-s-cut-coin:before { content: '\e94b'; } /* '' */
.icon-s-doc:before { content: '\e94c'; } /* '' */
.icon-s-edit:before { content: '\e94d'; } /* '' */
.icon-s-explorer:before { content: '\e94e'; } /* '' */
.icon-s-facebook:before { content: '\e94f'; } /* '' */
.icon-s-ff:before { content: '\e950'; } /* '' */
.icon-s-filter:before { content: '\e951'; } /* '' */
.icon-s-folder:before { content: '\e952'; } /* '' */
.icon-s-folder-open:before { content: '\e953'; } /* '' */
.icon-s-graph-cols:before { content: '\e954'; } /* '' */
.icon-s-graph-pie:before { content: '\e955'; } /* '' */
.icon-s-graph-rows:before { content: '\e956'; } /* '' */
.icon-s-grid-large:before { content: '\e957'; } /* '' */
.icon-s-grid-small:before { content: '\e958'; } /* '' */
.icon-s-heart-full:before { content: '\e959'; } /* '' */
.icon-s-heart-full-2:before { content: '\e95a'; } /* '' */
.icon-s-home:before { content: '\e95b'; } /* '' */
.icon-s-hourglass:before { content: '\e95c'; } /* '' */
.icon-s-instagram:before { content: '\e95d'; } /* '' */
.icon-s-list:before { content: '\e95e'; } /* '' */
.icon-s-locked:before { content: '\e95f'; } /* '' */
.icon-s-mail:before { content: '\e960'; } /* '' */
.icon-s-minus-coin:before { content: '\e961'; } /* '' */
.icon-s-new:before { content: '\e962'; } /* '' */
.icon-s-opera:before { content: '\e963'; } /* '' */
.icon-s-phone:before { content: '\e964'; } /* '' */
.icon-s-pin:before { content: '\e965'; } /* '' */
.icon-s-pix:before { content: '\e966'; } /* '' */
.icon-s-play:before { content: '\e967'; } /* '' */
.icon-s-plus-coin:before { content: '\e968'; } /* '' */
.icon-s-pricetag:before { content: '\e969'; } /* '' */
.icon-s-question-coin:before { content: '\e96a'; } /* '' */
.icon-s-ray:before { content: '\e96b'; } /* '' */
.icon-s-rewind:before { content: '\e96c'; } /* '' */
.icon-s-save:before { content: '\e96d'; } /* '' */
.icon-s-share:before { content: '\e96e'; } /* '' */
.icon-s-shopping-basket:before { content: '\e96f'; } /* '' */
.icon-s-shopping-cart:before { content: '\e970'; } /* '' */
.icon-s-sound-loud:before { content: '\e971'; } /* '' */
.icon-s-sound-mute:before { content: '\e972'; } /* '' */
.icon-s-sound-note:before { content: '\e973'; } /* '' */
.icon-s-sound-off:before { content: '\e974'; } /* '' */
.icon-s-sound-quiet:before { content: '\e975'; } /* '' */
.icon-s-star-full:before { content: '\e976'; } /* '' */
.icon-s-stop:before { content: '\e977'; } /* '' */
.icon-s-stop-coin:before { content: '\e978'; } /* '' */
.icon-s-stop-hand:before { content: '\e979'; } /* '' */
.icon-s-tag:before { content: '\e97a'; } /* '' */
.icon-s-twitter:before { content: '\e97b'; } /* '' */
.icon-s-unlocked:before { content: '\e97c'; } /* '' */
.icon-s-user:before { content: '\e97d'; } /* '' */
.icon-s-wallet:before { content: '\e97e'; } /* '' */
.icon-s-wallet-2:before { content: '\e97f'; } /* '' */
.icon-s-whatsapp:before { content: '\e980'; } /* '' */
.icon-s-window:before { content: '\e981'; } /* '' */
.icon-s-youtube:before { content: '\e982'; } /* '' */
.icon-b-double-chevron-u:before { content: '\e983'; } /* '' */
.icon-b-double-chevron-l:before { content: '\e984'; } /* '' */
.icon-b-double-chevron-r:before { content: '\e985'; } /* '' */
.icon-b-double-chevron-d:before { content: '\e986'; } /* '' */
